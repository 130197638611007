.serviceCard {
  @apply sm:w-[380px] flex-grow flex flex-col;
}

.serviceCard .serviceImWra {
  @apply max-w-full rounded-2xl relative flex-grow max-h-[300px] mb-3 min-h-[200px];
}

.serviceImWra .serviceImage {
  @apply object-cover w-full rounded-2xl h-full;
}

.serviceImWra .whiteLink {
  @apply absolute right-4 bottom-4 py-3 px-6 bg-white rounded-2xl;
}
.servicePricing {
  @apply flex flex-col items-center gap-2;
}

.servicePrice {
  @apply text-lg font-semibold text-gray-900;
}

.oldPrice {
  @apply line-through text-gray-500;
}

.couponTitle {
  @apply bg-white text-gray-900 px-4 py-2 rounded-lg shadow-md text-center w-full max-w-[220px] sm:max-w-[280px];
}

.couponName {
  @apply text-xl font-bold text-gray-900;
}

.couponDiscount {
  @apply text-lg font-semibold mt-1 text-red-600;
}

.newPrice {
  @apply text-lg font-bold text-green-600 mt-2;
}
